import { ComponentStyleConfig } from "@chakra-ui/react";
import { ExtendTheme } from "~/foundation/Theme";

export default <Partial<ExtendTheme>>{
	name: "Default",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.white",
					color: "primary.text",
					linkColor: "primary.aramcoLinkBlue",
				},
				"themed-link": {
					color: "primary.aramcoLinkBlue",
				},
			}
		}
	}
};

export const dark = <Partial<ExtendTheme>>{
	name: "Dark Grey",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.darkGrey",
					color: "primary.white",
					linkColor: "primary.aramcoBlue",
				},
				"themed-link": {
					color: "primary.aramcoBlue",
				},
			}
		}
	}
};