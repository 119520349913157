import React, { FC } from "react";
import { LatestFeaturedArticleProps } from "../generated-types";
import { SplitTeaserBaseFullLink } from "~/foundation/Components/SplitTeaserBase";
import { themable } from "~/foundation/Theme";
import * as variantThemes from "../LatestFeaturedArticle/variants";
import { dateFormatter } from "~/foundation/Utils/dateFormatter";
import { ImageField, useSitecoreContext } from "~/foundation/Jss";

const LatestFeaturedArticle: FC<LatestFeaturedArticleProps> = ({ rendering }) => {
	const featuredArticleData = rendering.customData?.featuredArticle;
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	if (!featuredArticleData) {
		return <></>;
	}

	const teaserImage: ImageField = featuredArticleData.image?.value?.src
		? featuredArticleData.image
		: { value: { src: sitecoreContext.custom.settings.imageFallback, alt: "" } };

	return (
		<SplitTeaserBaseFullLink
			fields={{
				teaserTrumpet: {
					value: featuredArticleData.publishDate && featuredArticleData.publishDate !== "" ? dateFormatter(new Date(featuredArticleData.publishDate as string), isRtl) : undefined
				},
				teaserHeadline: {
					value: featuredArticleData.title
				},
				teaserDescription: {
					value: featuredArticleData.teaser
				},
				teaserImage,
				teaserLink: {
					value: {
						href: featuredArticleData.url,
						text: featuredArticleData.linkText,
						linkType: "internal"
					}
				},
			}}
			dataSource={rendering.dataSource}
			imageLast={true}
			hasAudioFile={featuredArticleData.hasAudioFile}
			editable={false} />
	);
};

export default themable({ variantThemes })(LatestFeaturedArticle);